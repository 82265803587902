import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, delay, distinctUntilChanged, filter, firstValueFrom, map, merge, take, tap } from 'rxjs';

import { SearchApiService, SearchLocationDataActionQuery } from '@hiptraveler/data-access/api';
import { ItineraryAction } from '@hiptraveler/data-access/itinerary';
import { NavbarControlStateService, SearchLocationData } from '@hiptraveler/common';
import { SnackbarService } from '@hiptraveler/snackbar';
import { ComponentState } from './component-state.model';

const defaultState: ComponentState = {
  storyTitle: '',
  storyContent: '',
  hasStoryContent: false,
  bannerProcessing: false,
  processing: false,
  locationList: [],
  bannerAttribution: null,
  bannerImage: {
    url: 'assets/img/contactimg.webp',
    filename: 'business-contactimg'
  }
};

@Injectable()
export class ComponentStateService {

  subscription$$ = new BehaviorSubject<ComponentState>(defaultState);

  value$: Observable<ComponentState> = this.subscription$$.asObservable().pipe(
    filter(Boolean),
    delay(0)
  );

  processing$: Observable<boolean> = this.subscription$$.asObservable().pipe(
    map(e => !!e?.processing),
    distinctUntilChanged(),
    delay(0)
  );

  stateProcessing$: Observable<boolean> = merge(
    this.processing$,
    this.subscription$$.asObservable().pipe(
      map(e => !!e?.bannerProcessing),
      distinctUntilChanged(),
      delay(0)
    )
  );

  itineraries$: Observable<string[]> = this.value$.pipe(
    map((state: ComponentState) => state?.locationList?.map(e => e?.location || '').filter(Boolean) || [])
  );

  constructor(
    private store: Store,
    private searchApi: SearchApiService,
    private navbarControl: NavbarControlStateService,
    private snackbar: SnackbarService
  ) { }

  patch(state: ComponentState): void {
    this.subscription$$.next({ ...this.subscription$$.value, ...state });
  }

  reset(): void {
    this.subscription$$.next(defaultState);
  }

  /**
   * Removes a location from the itinerary at the specified index.
   * 
   * @param index The index of the location to remove.
   * @returns {void}
   */
  removeItineraryLocation(index: number): void {
    this.value$.pipe(
      filter(Boolean),
      tap((componentState: ComponentState) => {
        this.patch({ locationList: componentState?.locationList?.filter((_, i) => i !== index) });
        this.store.dispatch(new ItineraryAction.ModifyLocationList(index, 'remove'));
      }),
      take(1),
    ).subscribe();
  }

  async requestAndPatchLocationToState(locationData: SearchLocationData, storyTitle: string): Promise<void> {

    const { locationList = [] } = this.subscription$$.value || {};

    const query = { q: locationData!.location, locId: locationData!.locId, format: 'basic' } as SearchLocationDataActionQuery;
    const locationExists = locationList.find(e => e?.location === locationData?.location);

    if (locationExists) {
      this.snackbar.open({ message: 'The location you entered already exists. Please enter a new location.' });
      return;
    }

    this.navbarControl.navbarActionButtonState$$.next(true);
    const value1 = await firstValueFrom(this.value$);
    this.patch({ ...value1, processing: true, bannerProcessing: locationList.length! === 1 });

    try {

      const { locationInfo } = await firstValueFrom(this.searchApi.getLocationDataByQuery(query));

      this.store.dispatch(new ItineraryAction.ModifyLocationList(locationInfo, 'append'));
      
      const value2 = await firstValueFrom(this.value$);
      if (!value2?.bannerImage?.size) {
        const { gImgUrl, imgUrl, imgThumbnail } = locationInfo?.coverImg;
        this.patch({ ...value2, bannerImage: { url: gImgUrl || imgUrl || imgThumbnail } });
      }

      const locationData: SearchLocationData = {
        locId: locationInfo.id,
        name: locationInfo.city,
        country: locationInfo.country,
        location: locationInfo.formattedAddr
      };

      const value3 = await firstValueFrom(this.value$);
      this.patch({
        ...value3, storyTitle,
        locationList: [ ...(locationList || []), locationData ],
        processing: false, bannerProcessing: false
      });

    } catch (error) {
      this.snackbar.open({ message: 'Something went wrong. Please try again.', duration: 5000 });
    } finally {
      this.patch({ processing: false });
    }
  }

}
