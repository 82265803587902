import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { EMPTY, catchError, delay, forkJoin, from, map, of, switchMap, tap } from 'rxjs';
import { merge } from 'lodash';

import { ItineraryStateModel, ItineraryAction } from '.';
import * as API from '@hiptraveler/data-access/api';
import * as Helper from './helpers';
import { SearchAction } from '@hiptraveler/data-access/search';
import { NavbarControlStateService, SearchPageControlStateService } from '@hiptraveler/common';
import { SnackbarService } from '@hiptraveler/snackbar';

export const ITINERARY_STATE_TOKEN = new StateToken<ItineraryStateModel>('state_itinerary');

export const itineraryStateDefaults: any = null;

@State<ItineraryStateModel>({
  name: ITINERARY_STATE_TOKEN,
  defaults: itineraryStateDefaults
})

@Injectable()
export class ItineraryState implements NgxsOnInit {

  @Selector()
  static basicInfo(state: ItineraryStateModel): Partial<API.BasicInfoData> | null {
    return state?.basicInfo || null
  }

  @Selector()
  static actDateMap(state: ItineraryStateModel): API.ActivityDate | null {
    return state?.actDateMap || null;
  }

  @Selector()
  static actDateArr(state: ItineraryStateModel): API.ActivityDateData[] | null {
    return Helper.parseActDate(state?.actDateMap)?.arr || null;
  }

  @Selector()
  static actDate(state: ItineraryStateModel): API.ActivityDateData[] | null {
    return state?.actDateMap
      ? Object.keys(state.actDateMap).map(index => state.actDateMap![+index]).filter(e => !!e) as API.ActivityDateData[]
      : null;
  }

  @Selector()
  static itineraryReservations(state: ItineraryStateModel): API.ItineraryReservation[] | null {
    return state?.itineraryReservations || null;
  }

  @Selector()
  static imageInformation(state: ItineraryStateModel): API.ImageResultData | null {
    return state?.imageInformation || null;
  }

  @Selector()
  static hotelInformation(state: ItineraryStateModel): API.HotelResultData | null {
    if (state?.hotelInformation?.taDataMap?.hasOwnProperty('error')) {
      state.hotelInformation.taDataMap = null;
    }
    return state?.hotelInformation || null;
  }

  @Selector()
  static travelAgents(state: ItineraryStateModel): API.TravelAgentData[] | null {
    return state?.travelAgents || null;
  }

  @Selector()
  static resultRelatedTours(state: ItineraryStateModel): API.ImageResultData[] | null {
    return state?.resultRelatedTours || null;
  }

  constructor(
    private router: Router,
    private location: Location,
    private store: Store,
    private itineraryApi: API.ItineraryApiService,
    private imageApi: API.ImageApiService,
    private bookApi: API.BookApiService,
    private discoveryBoardApi: API.DiscoveryBoardApiService,
    private navbarControl: NavbarControlStateService,
    private searchPageControl: SearchPageControlStateService,
    private snackbar: SnackbarService
  ) { }

  ngxsOnInit(ctx: StateContext<ItineraryStateModel>): void {
    ctx.patchState({ itineraryReservations: [] });
  }

  @Action(ItineraryAction.GetBlogByPageTitle)
  getBlogByPageTitle(ctx: StateContext<ItineraryStateModel>, action: ItineraryAction.GetBlogByPageTitle) {
    return this.itineraryApi.getBlogByPageTitle(action.pageTitle).pipe(
      tap((response: API.GetBlogResponse) => {
        API.validateResponse(response, { ignoreKey: 'basicInfo' });
        ctx.patchState({ basicInfo: response.basicInfo });
      })
    );
  }

  @Action(ItineraryAction.GetItineraryByPageTitle, { cancelUncompleted: true })
  getItineraryByPageTitle(ctx: StateContext<ItineraryStateModel>, action: ItineraryAction.GetItineraryByPageTitle) {
    return this.itineraryApi.getItineraryByPageTitle(action.pageTitle).pipe(
      tap((response: API.GetItineraryResponse) => {
        API.validateResponse(response, { ignoreKey: 'basicInfo' });
        ctx.patchState({ basicInfo: response.basicInfo });
        Helper.replaceComposeUrlByNewItineraryResponse(this.location, response.basicInfo.pageTitle);
        Helper.updateUserItineraryByNewItineraryResponse(this.store, response.basicInfo.id!, {
          newPageTitle: response.basicInfo.pageTitle!,
          newTitle: response.basicInfo.title!
        });
      })
    );
  }

  @Action(ItineraryAction.GetBasicInfoByPageTitle, { cancelUncompleted: true })
  getBasicInfoByPageTitle(ctx: StateContext<ItineraryStateModel>, action: ItineraryAction.GetBasicInfoByPageTitle) {
    return this.itineraryApi.getItineraryByPageTitle(action.pageTitle).pipe(
      tap((response: API.GetItineraryResponse) => {
        API.validateResponse(response, { ignoreKey: 'basicInfo' });
        ctx.patchState({ basicInfo: response.basicInfo });
      })
    );
  }

  @Action(ItineraryAction.GetItineraryPageDataByPageTitle, { cancelUncompleted: true })
  getItineraryPageData(ctx: StateContext<ItineraryStateModel>, action: ItineraryAction.GetItineraryPageDataByPageTitle) {
    return from(Helper.getItineraryByPageTitle(
      action.pageTitle, this.itineraryApi, basicInfo => ctx.patchState({ basicInfo })
    )).pipe(
      tap(({ basicInfo, actDateMap, travelAgents, error }) => {
        if (error) return Helper.handleGetItineraryRequestError(this.router, this.snackbar);
        ctx.patchState({ basicInfo, actDateMap, travelAgents });
        basicInfo?.pageTitle && Helper.replaceComposeUrlByNewItineraryResponse(this.location, basicInfo.pageTitle);
        basicInfo?.pageTitle && Helper.updateUserItineraryByNewItineraryResponse(this.store, basicInfo.id!, {
          newPageTitle: basicInfo.pageTitle!,
          newTitle: basicInfo.title!
        });
      })
    );
  }
    
  @Action(ItineraryAction.GetItineraryTripByIdAndCount)
  getItineraryTripByIdAndCount(ctx: StateContext<ItineraryStateModel>, action: ItineraryAction.GetItineraryTripByIdAndCount) {
    const arr$ = Array(action.count).fill(0).map((_, count: number) => {
      return action.type === 'blog' 
        ? this.itineraryApi.getBlogTripByIdAndCount(action.id, ++count)
        : this.itineraryApi.getItineraryTripByIdAndCount(action.id, ++count);
    });
    return forkJoin(arr$).pipe(
      map((responses: API.GetItineraryTripByIdAndCountResponse[]) => merge({}, ...responses.map((result: API.GetItineraryTripByIdAndCountResponse) => {
        API.validateResponse(result, { ignoreKey: 'basicInfo' });
        return result?.itinerary?.actDateMap || null
      }))),
      tap((actDateMap: API.ActivityDate) => ctx.patchState({ actDateMap }))
    );
  }

  @Action(ItineraryAction.GetTravelAgentList)
  getTravelAgentList(ctx: StateContext<ItineraryStateModel>, { id }: ItineraryAction.GetTravelAgentList) {
    return this.itineraryApi.getTravelAgentList(id).pipe(
      tap((response: API.GetTravelAgentListResponse) => {
        API.validateResponse(response, { ignoreKey: 'travelAgents' });
        ctx.patchState({ travelAgents: response.travelAgents });
      })
    );
  }

  @Action(ItineraryAction.AddItineraryDay)
  addItineraryDay(ctx: StateContext<ItineraryStateModel>, { data }: ItineraryAction.AddItineraryDay) {
    return this.itineraryApi.addItineraryDay(data).pipe(
      tap((response: API.AddItineraryDayResponse) => {
        ctx.setState(patch<ItineraryStateModel>({ actDateMap: patch<any>({ [`${data.dayIndex}`]: response.actDateMap[data.dayIndex] }) }));
        data.param === 'blog'
          && ctx.setState(patch<ItineraryStateModel>({ basicInfo: patch<any>({ hasItinerary: true }) }));
        Helper.replaceComposeUrlByNewItineraryResponse(this.location, response.newPageTitle);
        Helper.updateUserItineraryByNewItineraryResponse(this.store, data.id, response);
      })
    );
  }

  @Action(ItineraryAction.ModifyLocationList)
  modifyLocationList(ctx: StateContext<ItineraryStateModel>, { data, state }: ItineraryAction.ModifyLocationList) {
    const locationList = [...(ctx.getState()?.basicInfo?.locationList || [])];
    if (state === 'append' && typeof data === 'object') {
      locationList.push(data);
    } else if (state === 'remove' && typeof data === 'number') {
      locationList.splice(data, 1);
    }
    ctx.setState(patch<ItineraryStateModel>({ basicInfo: patch<any>({ locationList }) }));
  }

  @Action(ItineraryAction.UpdateItineraryActivity)
  updateItineraryActivity(ctx: StateContext<ItineraryStateModel>, { data }: ItineraryAction.UpdateItineraryActivity) {
    return this.itineraryApi.updateItineraryActivity(data).pipe(
      tap((response: API.UpdateItineraryActivityResponse) => Helper.processUpdateItineraryActivity({
        location: this.location, navbarControl: this.navbarControl,
        store: this.store, ctx, data, response,
        itineraryActivityId: this.searchPageControl.activityDate$$.value?.itineraryId
      }))
    );
  }
 
  @Action(ItineraryAction.UpdateItineraryActivityByResponse)
  updateItineraryActivityByResponse(ctx: StateContext<ItineraryStateModel>, { data: value }: ItineraryAction.UpdateItineraryActivityByResponse) {
    return Helper.processUpdateItineraryActivity({
      location: this.location, navbarControl: this.navbarControl, store: this.store,
      ctx, data: value.dto, response: value.response,
      itineraryActivityId: this.searchPageControl.activityDate$$.value?.itineraryId
    });
  }

  @Action(ItineraryAction.AddItinerary)
  addItinerary(ctx: StateContext<ItineraryStateModel>, action: ItineraryAction.AddItinerary) {
    return this.itineraryApi.addItinerary(action.data).pipe(
      tap((response: API.AddItineraryResponse) => Helper.addItinerary(ctx, response, this.navbarControl))
    );
  }

  @Action(ItineraryAction.UploadItineraryBlog)
  uploadItineraryBlog(ctx: StateContext<ItineraryStateModel>, { data, param }: ItineraryAction.UploadItineraryBlog) {
    return this.itineraryApi.uploadItineraryBlog(data, param).pipe(
      tap((response: API.UploadItineraryBlogResponse) => {
        API.validateResponse(response, { ignoreKey: 'basicInfo' });
        Helper.updateItineraryState({ ctx, param, data, basicInfoResponse: response?.basicInfo });
      })
    );
  }

  @Action(ItineraryAction.AddReservation)
  addReservation(ctx: StateContext<ItineraryStateModel>, action: ItineraryAction.AddReservation) {
    return this.itineraryApi.addReservation(action.data).pipe(
      tap((response: API.AddReservationResponse) => {
        API.validateResponse(response, { ignoreKey: 'reservation' });
        Helper.addReservation(ctx, response);
      })
    );
  }

  @Action(ItineraryAction.AddItineraryBoard)
  addItineraryBoard(ctx: StateContext<ItineraryStateModel>, { id }: ItineraryAction.AddItineraryBoard) {
    return this.discoveryBoardApi.addItineraryBoard(id).pipe(
      tap(API.validateResponse),
      tap((response: API.AddItineraryBoardResponse) => {
        const parsedBasicInfo = Helper.addItineraryBoardParsedResponse(response, this.location);
        ctx.setState(patch<ItineraryStateModel>({ basicInfo: patch<API.BasicInfoData | any>(parsedBasicInfo) }));
        Helper.addUserItineraryByAddItineraryResponse(this.store, response);
        Helper.replaceUrlByNewItineraryResponse(this.location, response.data?.itiMap?.basicInfo?.pageTitle);
      })
    );
  }

  @Action(ItineraryAction.DeleteReservation)
  deleteReservation(ctx: StateContext<ItineraryStateModel>, action: ItineraryAction.DeleteReservation) {
    return this.itineraryApi.deleteReservation(action.data).pipe(
      tap((response: API.DeleteReservationResponse) => {
        API.validateResponse(response, { ignoreKey: 'action' });
        const reservations = ctx.getState()?.itineraryReservations || [];
        ctx.patchState({ itineraryReservations: reservations.filter(e => e.id !== action.data.id) })
      })
    );
  }

  @Action(ItineraryAction.GetImageInformation)
  getImageInformation(ctx: StateContext<ItineraryStateModel>, action: ItineraryAction.GetImageInformation) {
    return this.imageApi.getImageInformation(action.data).pipe( // To do: Reduce the number of lines of code.
      tap(API.validateResponse),
      catchError(() => { ctx.patchState({ imageInformation: action.value }); return EMPTY; }),
      switchMap(response => {
        const imageReqTaDataMap = response.data?.content?.taDataMap || null;
        const AAA$ = of(imageReqTaDataMap).pipe(
          tap(() => ctx.patchState({ imageInformation: response.data?.content }))
        );
        const BBB$ = this.bookApi.getTripAdvisorData(action.data).pipe(
          map(response => response.data || null),
          catchError(() => of(null)),
          tap(taDataMap => {
            taDataMap = taDataMap?.error ? null : taDataMap
            const imageInformation = { ...(response.data?.content || {}), taDataMap } as API.ImageResultData;
            ctx.patchState({ imageInformation })
          })
        );
        return imageReqTaDataMap ? AAA$ : BBB$;
      }) // To do: Reduce the number of lines of code.
    );
  }

  @Action(ItineraryAction.GetHotelInformation)
  getHotelInformation(ctx: StateContext<ItineraryStateModel>, action: ItineraryAction.GetHotelInformation) {
    return this.bookApi.getHotelInformation(action.data).pipe(
      tap(API.validateResponse),
      catchError(() => { ctx.patchState({ hotelInformation: action.value }); return EMPTY; }),
      tap(response => ctx.patchState({ hotelInformation: response.data }))
    );
  }

  @Action(ItineraryAction.GetFoodAndDrinkDialogData)
  getYelpContent(ctx: StateContext<ItineraryStateModel>, { data }: ItineraryAction.GetFoodAndDrinkDialogData) {
    return forkJoin([ // To do: Reduce the number of lines of code.
      this.imageApi.getImageInformation(data).pipe(tap(response => API.validateResponse(response))),
      this.imageApi.getYelpContent(data),
      this.bookApi.getTripAdvisorData(data)
    ]).pipe(
      tap(([ imageInformationResponse, yelpContentResponse, tripAdvisorDataResponse ]) => {
        const combinedObject = Helper.combineObject<API.ImageResultData>(imageInformationResponse.data?.content, yelpContentResponse.data?.content) as any;
        const taDataMap = tripAdvisorDataResponse.data?.error ? null : tripAdvisorDataResponse.data;
        ctx.patchState({ imageInformation: { ...combinedObject, taDataMap } });
      })
    );
  }

  @Action(ItineraryAction.GetResultRelatedTours)
  getResultRelatedTours(ctx: StateContext<ItineraryStateModel>, action: ItineraryAction.GetResultRelatedTours) {
    return this.bookApi.getResultRelatedTours(action.data).pipe(
      tap(API.validateResponse),
      catchError(() => { ctx.patchState({ resultRelatedTours: [] }); return EMPTY.pipe(delay(100)); }),
      tap(response => ctx.patchState({ resultRelatedTours: response.data?.imgResult || [] }))
    );
  }

  @Action(ItineraryAction.GetCachedDialogResultByData)
  getCachedDialogResultByData(ctx: StateContext<ItineraryStateModel>, { imageInformation, resultRelatedTours }: ItineraryAction.GetCachedDialogResultByData) {
    return of(null).pipe(
      tap(() => ctx.patchState({ imageInformation: imageInformation, resultRelatedTours }))
    );
  }

  @Action(ItineraryAction.ToggleLikeItinerary)
  toggleLikeItinerary(ctx: StateContext<ItineraryStateModel>, action: ItineraryAction.ToggleLikeItinerary) {
    const { data, type } = action;
    return this.imageApi.toggleLikeItinerary(data, type).pipe(
      tap(API.validateResponse),
      tap((response: any) => {
        ctx.patchState(Helper.toggleItineraryStateValue(ctx, action, response));
        ctx.dispatch(new SearchAction.UpdateSearchResultLike({
          id: action.data.id,
          results: Helper.favoriteResults(ctx, action, response)
        }));
      })
    );
  }

  @Action(ItineraryAction.MoveItineraryActivityPosition)
  moveItineraryActivityPosition(ctx: StateContext<ItineraryStateModel>, { value }: ItineraryAction.MoveItineraryActivityPosition) {
    const activity = ctx.getState()?.actDateMap?.[value.day] || null;
    const actDateMap = Helper.moveItineraryActivityValue(activity, value);
    ctx.setState(patch<ItineraryStateModel>({ actDateMap: patch<any>(actDateMap) }));
  }

  @Action(ItineraryAction.ModifyBasicInfo)
  modifyItineraryBasicInfo(ctx: StateContext<ItineraryStateModel>, { basicInfo }: ItineraryAction.ModifyBasicInfo) {
    ctx.setState(patch<ItineraryStateModel>({ basicInfo: patch<any>(basicInfo) }));
  }

  @Action(ItineraryAction.GetChecklistReservations)
  getChecklistReservations(ctx: StateContext<ItineraryStateModel>, { data }: ItineraryAction.GetChecklistReservations) {
    return this.itineraryApi.getChecklistReservations(data).pipe(
      tap((response) => {
        API.validateResponse(response, { ignoreKey: 'error' });
        ctx.patchState({ itineraryReservations: response?.reservations?.length ? response.reservations : [] });
      })
    );
  }

  @Action(ItineraryAction.SaveItineraryTripBlog)
  saveItineraryTripBlog(ctx: StateContext<ItineraryStateModel>, { data }: ItineraryAction.SaveItineraryTripBlog) {
    return this.itineraryApi.saveItineraryTripBlog(data).pipe(
      tap((response: API.SaveItineraryTripBlogResponse) => {
        API.validateResponse(response, { ignoreKey: 'basicInfo' });
        ctx.setState(patch<ItineraryStateModel>({
          basicInfo: patch<API.BasicInfoData | any>(
            Helper.saveItineraryTripBlogParseResponse(response, this.location)
          )
        }))
      })
    );
  }

  @Action(ItineraryAction.UpdateBasicInfoAuthorData)
  updateBasicInfoAuthorData(ctx: StateContext<ItineraryStateModel>, { author }: ItineraryAction.UpdateBasicInfoAuthorData) {
    ctx.setState(patch<ItineraryStateModel>({
      basicInfo: patch<API.BasicInfoData | any>({ author })
    }));
  }

  @Action(ItineraryAction.PartialResetItineraryState)
  PartialResetItineraryState(ctx: StateContext<ItineraryStateModel>, action: ItineraryAction.PartialResetItineraryState) {
    const data = Object.fromEntries( action.fields.map(field => [ field, null ]) );
    ctx.patchState({ ...ctx.getState(), ...data });
  }

}
