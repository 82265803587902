<div class="app-story-page-view router-page-container" navbarActionListener [class.processing]="!(service.basicInfo$ | async)" *ngIf="selections">

  <feature-page-banner [title]="(service.basicInfo$ | async)?.title!" [owner]="(common.owner$| async)!" [coverImage]="(service.basicInfo$ | async)?.coverImage!" [author]="(service.basicInfo$ | async)?.author!" [selections]="selections"></feature-page-banner>

  <div class="story">

    <div class="story--label-container"></div>
  
    <div class="story--content">
      <ng-container *ngIf="service.basicInfo$ | async as data">
        <div class="story--content-section" [@opacityFade]>

          <div class="story--content-section-label">
            <div class="story--content-section-label-container">
              <p translate="view-page.story"></p>
            </div>
          </div>

          <div [id]="selections[0].scrollToViewId"></div>
          <div class="story--content-section-html">
            <p class="story--content-section-html-info-label">{{ 'view-page.posted' | translate }} {{ data?.pubDateString || '-' }}</p>
            <p class="story--content-section-html-info-label">{{ 'view-page.photos' | translate }} {{ data?.author?.authorName || '-' }}</p>
            <br>
            <div class="story--content-section-html-editor hiptraveler-content-editable" [innerHTML]="data?.content | safeHtml"></div>
          </div>

        </div>
      </ng-container>
      <div class="story--content-section story--content-section-date-list">

        <div class="story--content-section-label">
          <div class="story--content-section-label-container">
            <p translate="view-page.itinerary"></p>
            <activity-date-list view></activity-date-list>
          </div>
        </div>
        
        <activity-date-list-container [count]="2" [actDate]="service.actDate$">
          <div [id]="selections[1].scrollToViewId"></div>
          <activity-day-list view type="blog"></activity-day-list>
        </activity-date-list-container>

      </div>
      <div class="story--content-section" *ngIf="(service.basicInfo$ | async)?.locationList?.length">

        <div class="story--content-section-label">
          <div class="story--content-section-label-container">
            <p translate="view-page.travel"></p>
          </div>
        </div>

        <div [id]="selections[2].scrollToViewId"></div>
        <div>
          <travel-details [@opacityFade] (selectedLocation)="travelOptions.selectedLocation = $event"></travel-details>
          <travel-options [@opacityFade]></travel-options>
        </div>

      </div>
      <div class="story--content-section">

        <div class="story--content-section-label">
          <div class="story--content-section-label-container">
          </div>
        </div>
        
        <div>
          <travel-share-handles [@opacityFade]></travel-share-handles>
        </div>

      </div>
    </div>
  
    <div class="story--map-wrapper">
      <ng-container *ngIf="true">
        <div class="story--map" leafletMap id="travel-story-view-page-map" [@opacityFadeIn]></div>
      </ng-container>
    </div>
  </div>

</div>

<travel-options style="position: absolute;opacity: 0;z-index: -1000;"></travel-options>
