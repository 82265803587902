import { Inject, Injectable, Optional } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { BehaviorSubject, Observable, Subject, debounceTime, delay, distinctUntilChanged, filter, map } from 'rxjs';
import { Request } from 'express';

import { Metadata } from '@hiptraveler/features/meta-tag';
import { globalStoreKey, removeCircularReferences } from '@hiptraveler/common';

@Injectable({
  providedIn: 'root'
})
export class AppListenerService {

  subscription$ = new Subject<void>();

  languageLoadState$$ = new BehaviorSubject<boolean>(false);
  languageLoadState$ = this.languageLoadState$$.asObservable();
  languageLoaded$ = this.languageLoadState$.pipe(filter(Boolean));
  
  clientWidth$$ = new BehaviorSubject<number>(0);
  clientWidth$ = this.clientWidth$$.asObservable();

  metaData$$ = new BehaviorSubject<Metadata | null>(null);
  metaData$ = this.metaData$$.asObservable().pipe(filter(Boolean), distinctUntilChanged((a, b) => a.title === b.title));

  experienceFinderVisibility$$ = new BehaviorSubject<boolean>(true);
  experienceFinderVisibility$ = this.experienceFinderVisibility$$.asObservable().pipe(delay(0));
  
  appSiteNavVisibility$$ = new BehaviorSubject<boolean>(true);
  appSiteNavVisibility$ = this.appSiteNavVisibility$$.asObservable().pipe(delay(0));
  
  appFooterVisibility$$ = new BehaviorSubject<boolean>(true);
  appFooterVisibility$ = this.appFooterVisibility$$.asObservable().pipe(delay(0));

  cardMenuPanelState$$ = new Subject<string>(); // Toggle open/close itinerary action panel
  cardMenuPanelState$ = this.cardMenuPanelState$$.asObservable().pipe(delay(0));

  loadMoreEventState$$ = new BehaviorSubject<boolean>(false);
  loadMoreEventState$ = this.loadMoreEventState$$.asObservable().pipe(delay(0));

  searchFilterDropdownState$$ = new BehaviorSubject<boolean>(false);
  searchFilterDropdownState$ = this.searchFilterDropdownState$$.asObservable().pipe(delay(0), distinctUntilChanged());
  
  mapsAutocompleteState$$ = new BehaviorSubject<Record<string, boolean>>({ '': false });
  mapsAutocompleteState$ = this.mapsAutocompleteState$$.asObservable().pipe(delay(0));
  
  mapsAutocompleteInput$$ = new BehaviorSubject<Record<string, string>>({ '': '' });
  mapsAutocompleteInput$ = this.mapsAutocompleteInput$$.asObservable().pipe(delay(0));

  mapsAutocompleteShadowState$$ = new BehaviorSubject<boolean>(true);
  mapsAutocompleteShadowState$ = this.mapsAutocompleteShadowState$$.asObservable();
  
  keyboardEvent$$ = new Subject<KeyboardEvent>();
  keyboardEvent$ = this.keyboardEvent$$.asObservable();
  
  mapVisibilityState$$ = new BehaviorSubject<boolean>(false);
  mapVisibilityState$ = this.mapVisibilityState$$.asObservable().pipe(filter(Boolean), distinctUntilChanged());

  previousUrl$$ = new BehaviorSubject<string>('');
  previousUrl$ = this.previousUrl$$.asObservable();
  previousUrl = this.previousUrl$$.value;
 
  constructor(
    @Optional() @Inject(REQUEST) private request: Request,
    private router: Router
  ) { }

  #allowBottomNavbarVisibilityEvent: boolean = true;
  bottomNavbarVisibility$$ = new BehaviorSubject<boolean>(true);
  bottomNavbarVisibility$ = this.bottomNavbarVisibility$$.asObservable().pipe(
    filter(() => this.#allowBottomNavbarVisibilityEvent)
  );
  allowButtonNavbarVisibilityEvent(): void {
    this.#allowBottomNavbarVisibilityEvent = true;
  }
  preventButtonNavbarVisibilityEvent(): void {
    this.#allowBottomNavbarVisibilityEvent = false;
  }

  private globalSignal$$ = new Subject<string>();
  emitGlobalSignal(signal: string): void {
    this.globalSignal$$.next(signal);
  }
  globalSignalListener(signal: string): Observable<string> {
    return this.globalSignal$$.asObservable().pipe(
      filter((globalSignal: string) => globalSignal === signal),
      debounceTime(100)
    );
  }

  globalStore$$ = new BehaviorSubject<Record<string, any>>({});
  getGlobalStore<T>(key: string): T {
    return this.globalStore$$.value[key];
  }
  setGlobalStore<T>(key: string, value: T): void {
    const storeValue = { ...this.globalStore$$.value, [key]: value };
    sessionStorage.setItem(globalStoreKey, JSON.stringify(storeValue, removeCircularReferences()));
    this.globalStore$$.next(storeValue);
  }
  clearGlobalStore<T>(key: string): void {
    const storeValue = { ...this.globalStore$$.value };
    delete storeValue?.[key];
    sessionStorage.setItem(globalStoreKey, JSON.stringify(storeValue, removeCircularReferences()));
    this.globalStore$$.next(storeValue);
  }
  globalStore<T>(key: string): Observable<T> {
    return this.globalStore$$.asObservable().pipe(
      map(store => store?.[key]),
      filter(Boolean)
    );
  }

  get navigationStart$(): Observable<string> {
    return this.router.events.pipe(
      filter((event: Event): event is NavigationStart => event instanceof NavigationStart),
      map((event: NavigationStart) => event.url),
      distinctUntilChanged()
    );
  }

  get serverRequest(): boolean {

    const hostname = this.request?.hostname;
    const validPathname = this.request?.path !== '/null';
    const validHostname = hostname?.includes('hiptraveler.com') || hostname === 'localhost';

    return validHostname && validPathname && !this.invalidRequest;
  }

  get invalidRequest(): boolean {
    return [ '/trip-planner/', '/api/auth/' ].some(e => this.request?.url?.includes(e));
  }

}
