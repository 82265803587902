import * as API from '@hiptraveler/data-access/api';
import { ItineraryActivityPosition, ItineraryStateField } from './itinerary.model';

export class AddItinerary {
  static readonly type = '[Itinerary State] AddItinerary';
  constructor(public data: API.AddItineraryDto) { }
}

export class AddItineraryDay {
  static readonly type = '[Itinerary State] AddItineraryDay';
  constructor(public data: API.AddItineraryDayDto) { }
}

export class ModifyLocationList {
  static readonly type = '[Itinerary State] ModifyLocationList';
  constructor(public data: API.LocationData | number, public state: 'append' | 'remove') { }
}

export class UpdateItineraryActivity {
  static readonly type = '[Itinerary State] UpdateItineraryActivity';
  constructor(public data: API.UpdateItineraryActivityDto) { }
}

export class UpdateItineraryActivityByResponse {
  static readonly type = '[Itinerary State] UpdateItineraryActivityByResponse';
  constructor(public data: API.UpdateItineraryActivityResponseDto) { }
}

export class UploadItineraryBlog<T = any> {
  static readonly type = '[Itinerary State] UploadItineraryBlog';
  constructor(public data: T, public param: API.ItineraryParam) { }
}

export class GetBlogByPageTitle {
  static readonly type = '[Itinerary State] GetBlogByPageTitle';
  constructor(public pageTitle: string) { }
}

export class GetItineraryByPageTitle {
  static readonly type = '[Itinerary State] GetItineraryByPageTitle';
  constructor(public pageTitle: string) { }
}

export class GetBasicInfoByPageTitle {
  static readonly type = '[Itinerary State] GetBasicInfoByPageTitle';
  constructor(public pageTitle: string) { }
}

export class GetItineraryPageDataByPageTitle {
  static readonly type = '[Itinerary State] GetItineraryPageDataByPageTitle';
  constructor(public pageTitle: string) { }
}

export class GetItineraryTripByIdAndCount {
  static readonly type = '[Itinerary State] GetItineraryTripByIdAndCount';
  constructor(public id: string, public count: number, public type: API.ItineraryParam = 'itinerary') { }
}

export class GetTravelAgentList {
  static readonly type = '[Itinerary State] GetTravelAgentList';
  constructor(public id: string) { }
}

export class AddReservation {
  static readonly type = '[Itinerary State] AddReservation';
  constructor(public data: API.AddReservationDto) { }
}

export class AddItineraryBoard {
  static readonly type = '[Itinerary State] AddItineraryBoard';
  constructor(public id: string) { }
}

export class DeleteReservation {
  static readonly type = '[Itinerary State] DeleteReservation';
  constructor(public data: API.RequestByIdDto) { }
}

export class GetImageInformation {
  static readonly type = '[Itinerary State] GetImageInformation';
  constructor(public data: API.RequestByIdDto, public value: API.ImageResultData) { }
}

export class GetHotelInformation {
  static readonly type = '[Itinerary State] GetHotelInformation';
  constructor(public data: API.RequestByIdDto, public value: API.HotelResultData) { }
}

export class GetFoodAndDrinkDialogData {
  static readonly type = '[Itinerary State] GetFoodAndDrinkDialogData';
  constructor(public data: API.RequestByIdDto) { }
}

export class GetResultRelatedTours {
  static readonly type = '[Itinerary State] GetResultRelatedTours';
  constructor(public data: API.RequestByIdDto) { }
}

export class GetCachedDialogResultByData {
  static readonly type = '[Itinerary State] GetCachedDialogResultByData';
  constructor(public imageInformation: API.ImageResultData, public resultRelatedTours: API.ImageResultData[]) { }
}

export class ToggleLikeItinerary {
  static readonly type = '[Itinerary State] ToggleLikeItinerary';
  constructor(public data: API.ToggleLikeItineraryDto, public type: 'image' | 'hotel') { }
}

export class MoveItineraryActivityPosition {
  static readonly type = '[Itinerary State] MoveItineraryActivityPosition';
  constructor(public value: ItineraryActivityPosition) { }
}

export class ModifyBasicInfo {
  static readonly type = '[Itinerary State] ModifyBasicInfo';
  constructor(public basicInfo: Partial<API.BasicInfoData>) { }
}

export class GetChecklistReservations {
  static readonly type = '[Itinerary State] GetChecklistReservations';
  constructor(public data: API.RequestByIdDto) { }
}

export class SaveItineraryTripBlog {
  static readonly type = '[Itinerary State] SaveItineraryTripBlog';
  constructor(public data: API.SaveItineraryTripBlogDto) { }
}

export class UpdateBasicInfoAuthorData {
  static readonly type = '[Itinerary State] UpdateBasicInfoAuthorData';
  constructor(public author: API.AuthorData) { }
}

export class PartialResetItineraryState {
  static readonly type = '[Itinerary State] PartialResetItineraryState';
  constructor(public fields: ItineraryStateField[]) { }
}
